import React from "react";
import { FilesViewProps } from "components/atoms/FilesView/types";
import { Container, Img } from "components/atoms/FilesView/styles";

const FilesView: React.FC<FilesViewProps> = ({ files }) => {
  return (
    <Container>
      {files?.map((item: any) => {
        return <Img src={item?.url} />;
      })}
    </Container>
  );
};

export default FilesView;
