import { useMutation, useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  FormFieldsDataType,
  GetFormFieldsResponseError,
  SubmitRequestData,
  SubmitResponseData,
  CancelRequestData,
  CancelResponseData,
  TranslationsResponseData,
} from "modules/dynamicForm/types";
import { useNavigate, generatePath } from "react-router-dom";
import APP_ROUTES from "system/router/constants";
import {
  GET_FORM_TEMPLATE_URL,
  CANCEL_TRANSACTION_URL,
  SUBMIT_TRANSACTION_URL,
  GET_TRANSLATIONS_URL,
} from "system/api/apiUrls";
import { useTrans } from "system/translations/hooks";
import { checkTransParams } from "system/helpers/helperFunctions";
import { toast } from "react-hot-toast";

const formFieldsQuery = "form_fields";
const getTranslationsQuery = "getTranslationsQuery";
export const useGetFormFields = (transactionId: string = "") => {
  const [apiRequest] = useApiRequest();
  const navigate = useNavigate();
  const { _t } = useTrans();
  return useQuery(
    formFieldsQuery,
    (): Promise<FormFieldsDataType> =>
      apiRequest({
        url: generatePath(GET_FORM_TEMPLATE_URL, { transactionId }),
        method: "get",
      }),
    {
      select: (
        data: FormFieldsDataType
      ): FormFieldsDataType & {
        images: { [key: string]: any };
      } => {
        let fields: any = [];
        let styles: any = {};
        let images: any = {};
        try {
          fields = checkTransParams(JSON.parse(data?.properties)?.fields, _t);
          styles = JSON.parse(data?.properties)?.styles?.styles;
          data.formTemplateImages?.forEach((item) => {
            if (images.hasOwnProperty(item.name)) {
              images[item.name] = [...images[item.name], { ...item }];
            } else {
              images[item.name] = [{ ...item }];
            }
          });
        } catch (e) {}
        return { ...data, fields, styles, images };
      },
      onError: (err: GetFormFieldsResponseError) => {
        if (err?.status === 409) {
          navigate(
            generatePath(APP_ROUTES.progress, {
              transactionId: transactionId,
            })
          );
          setTimeout(() => {
            err?.data?.returnUrl &&
              window.location.assign(err?.data?.returnUrl);
          }, 5000);
          return;
        }
        if (err?.status === 422) {
          navigate(
            generatePath(APP_ROUTES.transactionError, {
              transactionId: transactionId,
            })
          );
          return;
        }
        if (err?.status >= 400) {
          navigate(
            generatePath(APP_ROUTES.transactionError, {
              transactionId: transactionId,
            })
          );
          return;
        }
      },
    }
  );
};

export const useSubmitTransaction = () => {
  const [apiRequest] = useApiRequest();
  const navigate = useNavigate();
  return useMutation(
    (data: SubmitRequestData) =>
      apiRequest({
        url: generatePath(SUBMIT_TRANSACTION_URL, {
          transactionId: data.transactionId,
        }),
        method: "POST",
        data: data?.data,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    {
      onSuccess: (data: SubmitResponseData) => {
        if (data?.returnUrl) {
          setTimeout(() => {
            window.location.assign(data?.returnUrl);
          }, 5000);
        }
      },
      onError: (err: GetFormFieldsResponseError, variable) => {
        if (err?.status === 409) {
          navigate(
            generatePath(APP_ROUTES.progress, {
              transactionId: variable?.transactionId,
            })
          );
        }
        if (err?.status === 400) {
          if (err?.errorData?.message) {
            toast.error(err?.errorData?.message);
          }
        }
      },
    }
  );
};
export const useCancelTransaction = () => {
  const [apiRequest] = useApiRequest();
  return useMutation(
    (data: CancelRequestData) =>
      apiRequest({
        url: generatePath(CANCEL_TRANSACTION_URL, {
          transactionId: data.transactionId,
        }),
        method: "POST",
      }),
    {
      onSuccess: (data: CancelResponseData) => {
        if (data?.returnUrl) {
          window.location.assign(data?.returnUrl);
        }
      },
      onError: (err: any) => {},
    }
  );
};

export const useGetTranslations = (transactionId: string = "") => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    getTranslationsQuery,
    (): Promise<TranslationsResponseData> =>
      apiRequest({
        url: generatePath(GET_TRANSLATIONS_URL, { transactionId }),
        method: "get",
      })
  );
};
