const defaultTranslations = {
  close_window: "Close this window",
  error404_title: "404",
  request_created: "Request created",
  request_created_description: "Request created description",
  copied: "Сopied",
  error_message_title: "Something went wrong",
  error_message_description:
    "There was an error with your transaction. Try again or talk to our customer services team.",
  progress_message_title: "Transaction In progress",
  progress_message_description:
    "Transaction has been submitted and is waiting for confirmation.You can safely close this window and return to homepage.",
};

export default defaultTranslations;
