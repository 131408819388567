import React, { useRef } from "react";
import { UploadBtnProps } from "components/atoms/UploadBtn/types";
import {
  DeleteIcon,
  UploadBtnElement,
  UploadBtnIcon,
  UploadBtnInfo,
  Error,
} from "components/atoms/UploadBtn/styles";

const UploadBtn: React.FC<UploadBtnProps> = ({
  id = "input",
  label,
  onClick,
  styles = {},
  kind,
  value,
  onChange,
  onBlur,
  error,
  name,
  ...rest
}) => {
  const fileRef: any = useRef(null);
  const onClickHandler = () => {
    fileRef.current?.click();
  };
  const handleFileChange = (e: any) => {
    if (!e.target.files) {
      return;
    }
    onChange(e.target.files[0]);
    setTimeout(() => {
      onBlur(name);
    }, 300);
  };
  const onDelete = () => {
    if (fileRef.current) {
      fileRef.current.value = null;
    }
    onChange(null);
  };
  const fileName = value?.name || "";
  const slicedFileName = fileName.slice(
    fileName.length > 27 ? fileName.length - 27 : 0,
    fileName.length
  );
  return (
    <div>
      <UploadBtnElement
        className={kind}
        kind={kind}
        onClick={onClickHandler}
        styles={styles}
      >
        <UploadBtnIcon />
        <span>{label}</span>
        <input
          type={"file"}
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileRef}
          name={name}
        />
      </UploadBtnElement>
      {fileName && (
        <UploadBtnInfo>
          <span>{slicedFileName}</span>
          <DeleteIcon onClick={onDelete} />
        </UploadBtnInfo>
      )}
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default UploadBtn;
