import styled from "styled-components/macro";
import themeOptions from "system/styles/themeOptions";

export const Container = styled.div`
  padding: ${themeOptions.spacing.sm} ${themeOptions.spacing.md};
  border-radius: ${themeOptions.borders.sm};
  background: ${(props) => props.theme.secondaryColor};
`;
export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${themeOptions.spacing.sm} 0;
  border-bottom: 1px solid;
  border-color: ${(props) => props?.theme?.secondaryColor};
  font-size: 14px;
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;
export const ItemValue = styled.span`
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  gap: ${themeOptions.spacing.xxs};
  align-items: flex-start;
  svg {
    cursor: pointer;
    stroke: ${(props) => props.theme.primaryColor} !important;
    &:hover {
      transform: scale(1.08);
    }
  }
`;
export const ItemLabel = styled.span`
  color: ${(props) => props.theme.paragraphColor};
`;
