import styled from "styled-components/macro";

export const FormItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const FieldWrap = styled.div<{
  width: string;
  align: string;
  textAlign: string;
}>`
  margin: ${(props) => props?.align};
  margin-bottom: ${(props) => props?.theme?.fieldsSpace};
  width: ${(props) => `calc(${props.width} - 10px)`};
  text-align: ${(props) => props?.textAlign};
`;
