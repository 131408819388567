import styled from "styled-components/macro";
import themeOptions from "system/styles/themeOptions";
import { UploadBtnProps } from "components/atoms/UploadBtn/types";
import { rgba } from "system/styles/helpers";
import { ReactComponent as UploadIcon } from "images/icons/upload.svg";
import { ReactComponent as CloseIcon } from "images/icons/close.svg";
import { StylesType } from "components/atoms/Input/types";

export const UploadBtnElement = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeOptions.spacing.sm};
  max-height: 48px;
  padding: ${themeOptions.spacing.sm} ${themeOptions.spacing.md};
  font-size: 14px;
  cursor: pointer;
  border-radius: ${(props) => props?.theme?.fieldsBorderRadius};
  border: 1px solid ${(props) => props?.theme?.actionBtnColor};
  background: ${(props) => props?.theme?.actionBtnColor};
  color: ${themeOptions.colors.white};
  &:hover {
    background: ${(props) => props?.theme?.actionBtnActiveColor};
    border-color: ${(props) => props?.theme?.actionBtnActiveColor};
  }
`;
export const UploadBtnIcon = styled(UploadIcon)<any>`
  fill: ${themeOptions.colors.white};
`;
export const UploadBtnInfo = styled.span<any>`
  font-size: 12px;
  color: ${(props) => props?.theme?.paragraphColor};
  display: flex;
  gap: ${themeOptions.spacing.xs};
  align-items: center;
  margin-top: ${themeOptions.spacing.xs};
`;

export const DeleteIcon = styled(CloseIcon)<any>`
  fill: ${themeOptions.colors.white};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transform: scale(1.1);
  }
`;

export const Error = styled.div<StylesType>`
  color: ${(props) => props?.theme?.errorColor};
  font-size: 12px;
`;
