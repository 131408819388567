import React from "react";

const FormItem = ({
  emptySpace,
  columnParams: { className, style, ...rest },
  Component,
  additionalProps,
  name,
  componentProps,
  error,
  onBlur,
  onChange,
  formik,
  formProps,
  getNestedValue,
  ...restComponentProps
}: any) => {
  return (
    <Component
      {...componentProps}
      {...additionalProps}
      {...restComponentProps}
      name={name}
      id={name}
      value={getNestedValue(formik.values, name)}
      error={error || null}
      onBlur={() => onBlur(name)}
      onFormValueChange={(fieldName: string, value: any) =>
        onChange(fieldName, value)
      }
      onChange={(value: any, directFieldName?: string) => {
        const fieldName: string = directFieldName || name;
        onChange(fieldName, value);
        formProps.onFormValueChange &&
          formProps.onFormValueChange(fieldName, formik);
        componentProps.onFieldChange &&
          componentProps.onFieldChange(value, formik.values, {
            setFieldValue: formik.setFieldValue,
            setFieldTouched: formik.setFieldTouched,
          });
      }}
    >
      {componentProps.children}
    </Component>
  );
};

export default FormItem;
