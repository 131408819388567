import React, { useMemo, useRef } from "react";
import FormWrap from "components/atoms/FormWrap";
import FormBuilder from "services/formBuilder/FormBuilder";
import {
  useGetFormFields,
  useSubmitTransaction,
  useCancelTransaction,
} from "modules/dynamicForm/apiHooks";
import { createYupSchema } from "modules/dynamicForm/helper";
import { useParams, useNavigate, generatePath } from "react-router-dom";
import { useThemeData } from "system/styles/hooks";
import { ThemeProvider } from "styled-components";
import { useGenerateFields } from "modules/dynamicForm/hooks";
import Loader from "components/atoms/Loader";
import APP_ROUTES from "system/router/constants";
import { checkIsFile, parseFormData } from "system/helpers/helperFunctions";

const DynamicForm = () => {
  const dynamicFormRef: any = useRef(null);
  let urlParams = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetFormFields(urlParams?.transactionId);
  const { mutateAsync: onSubmitTransaction, isLoading: isSubmitting } =
    useSubmitTransaction();
  const { mutateAsync: onCancelTransaction, isLoading: isCanceling } =
    useCancelTransaction();
  const theme = useThemeData(data?.styles);
  const onSubmit = async () => {
    await dynamicFormRef?.current?.formik?.validateForm();
    dynamicFormRef?.current?.formik?.handleSubmit();
    if (dynamicFormRef?.current?.formik?.isValid) {
      const values = dynamicFormRef?.current?.formik?.values;
      let prepareData: any = {
        request: {},
        files: {},
      };
      for (let key in values) {
        const isFile = checkIsFile(values[key]);
        if (!isFile && !!values[key]) {
          prepareData["request"][key] = values[key];
        } else if (isFile) {
          prepareData["files"][key] = values[key];
        }
      }
      const reqFormData = parseFormData(prepareData);
      await onSubmitTransaction({
        data: reqFormData,
        transactionId: urlParams?.transactionId || "",
      });
      await navigate(
        generatePath(APP_ROUTES.success, {
          transactionId: urlParams?.transactionId,
        })
      );
    }
  };
  const onCancel = async () => {
    await onCancelTransaction({
      transactionId: urlParams?.transactionId || "",
    });
    /*      await navigate(
        generatePath(APP_ROUTES.success, {
          transactionId: urlParams?.transactionId,
        })
      );*/
  };
  const config = useGenerateFields(data?.fields, {
    onSubmit: onSubmit,
    onCancel: onCancel,
    images: data?.images,
    credentials: data?.credentials,
    transactionDTO: data?.transactionDTO
  });
  const validationSchema = useMemo(() => {
    if (data) {
      return createYupSchema(data?.fields);
    }
    return [];
  }, [data]);
  if (isLoading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormWrap>
          {(isSubmitting || isCanceling) && <Loader overflow />}
          <FormBuilder
            ref={dynamicFormRef}
            formProps={{
              initialValues: {},
              onSubmit: () => {},
              validationSchema: validationSchema,
            }}
            formItemsConfig={config}
          />
        </FormWrap>
      </div>
    </ThemeProvider>
  );
};

export default DynamicForm;
