import React from "react";
import { InputProps } from "components/atoms/Input/types";
import {
  Container,
  InputElement,
  Label,
  Error,
} from "components/atoms/Input/styles";

const Input: React.FC<InputProps> = ({
  id = "input",
  label,
  value,
  onChange,
  hideLabel = false,
  styles = {},
  placeholder,
  error,
  additionalStyles,
  ...rest
}) => {
  return (
    <Container>
      <Label styles={styles} isError={!!error}>
        {label}
      </Label>
      <InputElement
        isError={!!error}
        styles={styles}
        placeholder={placeholder}
        id={id}
        onChange={(e: any) => onChange && onChange(e?.target?.value)}
        value={value}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Input;
