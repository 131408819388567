import React, { useRef } from "react";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "images/icons/dropdown-arrow.svg";
import { SimpleSelectProps } from ".";
import { useCheckClickOutside } from "system/helpers/hooks";
import { getFirstObjInArray } from "system/helpers/helperFunctions";
import { Container, SelectContainer, Label } from "./styles";

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  name,
  value,
  options,
  onChange,
  className,
  placeholder,
  error,
  disabled,
  isObjValue,
  isCustomBg,
  isRelativeMenu,
  placement,
  label,
}) => {
  const selectRef = useRef(null);
  const [open, setOpen] = useCheckClickOutside(selectRef, false);
  const handleClick = (val: any) => {
    onChange && onChange(isObjValue ? getFirstObjInArray(options, val) : val);
    setOpen(false);
  };
  const selectedEntity = value?.hasOwnProperty("value")
    ? value
    : getFirstObjInArray(options, value);

  return (
    <Container>
      <Label>{label}</Label>
      <SelectContainer
        className={classNames(
          "simple-select",
          {
            "no-placeholder": !placeholder,
            disabled: disabled,
          },
          className
        )}
        ref={selectRef}
      >
        <div
          className={classNames("simple-select__wrapper", { "is-open": open })}
          onClick={() => setOpen(!open)}
        >
          {!selectedEntity?.value && (
            <span
              className={classNames("simple-select__placeholder", {
                centered: selectedEntity === undefined && !open,
              })}
            >
              {placeholder}
            </span>
          )}

          <label>
            {selectedEntity?.label}
            {selectedEntity?.img && (
              <img
                src={selectedEntity.img}
                className="simple-select__icon-img"
                alt={"icon"}
              />
            )}
          </label>
          <input
            name={name}
            value={selectedEntity?.value}
            className={classNames("simple-select__input", {
              "custom-bg": isCustomBg,
            })}
            readOnly
            disabled={disabled}
          />
          <ArrowIcon
            className={classNames("dropdown-arrow", {
              flipped: open,
            })}
          />
        </div>
        {open && (
          <div
            className={classNames(
              "simple-select-options",
              { "relative-menu": isRelativeMenu },
              placement
            )}
          >
            <ul className="simple-select-options__wrap">
              {options.map(({ value, img, label }, key) => (
                <li
                  key={key}
                  value={value}
                  onClick={() => handleClick(value)}
                  className={classNames(
                    "simple-option",
                    {
                      "current-selected": value === selectedEntity?.value,
                    },
                    { "with-img": !!img }
                  )}
                >
                  <span className="simple-option__label">{label}</span>
                  {img && (
                    <img
                      src={img}
                      className="simple-select__icon-img"
                      alt={"icon"}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
        {error && <div className="simple-select__error">{error}</div>}
      </SelectContainer>
    </Container>
  );
};

SimpleSelect.defaultProps = {
  placeholder: "",
  className: "",
  error: null,
  disabled: false,
  isObjValue: false,
  isCustomBg: false,
  isRelativeMenu: false,
  placement: "bottom",
};

export default SimpleSelect;
