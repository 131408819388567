import React from "react";
import { ButtonProps } from "components/atoms/Button/types";
import { ButtonElement } from "components/atoms/Button/styles";

const Button: React.FC<ButtonProps> = ({
  id = "input",
  label,
  onClick,
  styles = {},
  kind,
  type = "button",
}) => {
  return (
    <ButtonElement
      className={kind}
      kind={kind}
      type={type}
      onClick={onClick}
      styles={styles}
    >
      {label}
    </ButtonElement>
  );
};

export default Button;
