import styled from "styled-components/macro";

export const Container = styled.div`
  background: ${(props) => props?.theme?.mainBgColor};
  padding: ${(props) =>
    `${props?.theme?.verticalInnerSpace} ${props?.theme?.horizontalInnerSpace}`};
  border-radius: ${(props) => props?.theme?.bgBorderRadius};
  max-width: 400px;
  position: relative;
`;
