import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,body,#root {
    height: 100%;
    margin: 0;
    font-family: "Inter",serif;
    font-style: normal;
  }
`;

export default GlobalStyle;
