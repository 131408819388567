import React, {
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormik } from "formik";
import { useFormikHelper } from "services/formBuilder/hooks";
import { FormBuilderProps } from "services/formBuilder/types";
import { getNestedValue } from "system/helpers/helperFunctions";
import FormItem from "services/formBuilder/FormItem";
import { FieldWrap } from "services/formBuilder/styles";
import { FormItemsContainer } from "services/formBuilder/styles";

const FormBuilder: React.FC<FormBuilderProps> = forwardRef(
  ({ formProps, formItemsConfig, showSubmit = false }, ref) => {
    const formInitialValues = useMemo(() => {
      let values = formProps.initialValues || {};
      formItemsConfig.forEach(({ name, componentProps }: any) => {
        if (
          !!name &&
          !Array.isArray(name) &&
          !values.hasOwnProperty(name) &&
          !name.includes(".")
        ) {
          values[name] = componentProps.defaultValue || undefined;
        }
      });
      return values;
    }, [formItemsConfig]);
    const formik: any = useFormik({
      ...formProps,
      enableReinitialize: true,
      initialValues: formInitialValues,
    });
    useEffect(() => {
      formProps.onFormValuesChange &&
        formProps.onFormValuesChange(formik.values, formik);
    }, [formik.values]);
    useImperativeHandle(ref, () => ({
      formik,
    }));
    const resetField = (fieldName: any) => {
      const initialValue = formProps?.initialValues?.[fieldName];
      formik.setFieldValue(fieldName, initialValue);
    };
    const onReset = () => {
      formProps?.onReset && formProps?.onReset();
    };
    const { error, onBlur, onChange } = useFormikHelper(formik);
    return (
      <form noValidate onSubmit={formik.handleSubmit}>
        <FormItemsContainer>
          {formItemsConfig?.map((item: any) => {
            let {
              component: Component,
              componentProps: formItemProps,
              name,
              columnParams = {},
              emptySpace,
              hidden,
              hasValues,
              elementType = "formElement",
            } = item;
            if (hidden) {
              return null;
            }
            const componentProps =
              typeof formItemProps === "function"
                ? formItemProps(formik)
                : formItemProps;
            const additionalProps: any = {};
            if (componentProps?.type === "submit") {
              additionalProps.formOnSubmit = formik.handleSubmit;
            }
            if (!!hasValues) {
              additionalProps.values = formik.values;
            }
            if (elementType === "additionalComponent") {
              return (
                <FieldWrap
                  width={componentProps?.fieldWidth || "100%"}
                  align={componentProps?.fieldAlign || "0 5px 0 5px"}
                  textAlign={componentProps?.textAlign || "left"}
                >
                  <Component {...componentProps} />
                </FieldWrap>
              );
            }
            if (!!hasValues) {
              additionalProps.values = formik.values;
            }
            return (
              <FieldWrap
                width={componentProps?.fieldWidth || "100%"}
                align={componentProps?.fieldAlign || "0 5px 0 5px"}
                textAlign={componentProps?.textAlign || "left"}
              >
                <FormItem
                  emptySpace={emptySpace}
                  columnParams={columnParams}
                  Component={Component}
                  additionalProps={additionalProps}
                  name={name}
                  componentProps={componentProps}
                  error={error(name)}
                  onBlur={onBlur}
                  onChange={onChange}
                  formik={formik}
                  onReset={onReset}
                  formProps={formProps}
                  getNestedValue={getNestedValue}
                />
              </FieldWrap>
            );
          })}
        </FormItemsContainer>
      </form>
    );
  }
);

export default FormBuilder;
