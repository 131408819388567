import React from "react";
import { Container, MessageWrapper } from "pages/success/styles";
import { ReactComponent as CheckmarkIcon } from "images/icons/checkmark.svg";
import Headline from "components/atoms/Headline";
import Paragraph from "components/atoms/Paragraph";
import { isWindowClosable } from "system/helpers/helperFunctions";
import Button from "components/atoms/Button";
import { useTrans } from "system/translations/hooks";

const SuccessPage = () => {
  const { _t } = useTrans();
  const closeWindow = () => {
    window.close();
  };
  return (
    <Container>
      <MessageWrapper>
        <CheckmarkIcon />
        <Headline size={"24px"} value={_t("request_created")} />
        <Paragraph size={"14px"} value={_t("request_created_description")} />
        {isWindowClosable && (
          <Button
            onClick={closeWindow}
            kind={"reset"}
            label={_t("close_window")}
          />
        )}
      </MessageWrapper>
    </Container>
  );
};

export default SuccessPage;
