import React from "react";
import { Route, Routes, Router } from "react-router-dom";
import Error404Page from "pages/error404";
import ProgressPage from "pages/progress";
import MainPage from "pages/main";
import SuccessPage from "pages/success";
import APP_ROUTES from "system/router/constants";
import TransactionErrorPage from "pages/transactionError";

type RouteType = {
  path: string;
  element: any;
  exact?: boolean;
};

const routes: RouteType[] = [
  {
    path: "*",
    element: <Error404Page />,
  },
  {
    path: APP_ROUTES.progress,
    element: <ProgressPage />,
  },
  {
    path: APP_ROUTES.mainForm,
    element: <MainPage />,
  },
  {
    path: APP_ROUTES.success,
    element: <SuccessPage />,
  },
  {
    path: APP_ROUTES.transactionError,
    element: <TransactionErrorPage />,
  },
];

export default (
  <Routes>
    {routes.map((route) => {
      return <Route key={route.path} {...route} />;
    })}
  </Routes>
);
