import axiosClient from "./axiosConfig";

export const useApiRequest = () => {
  const apiRequest = (params: any) =>
    axiosClient
      .request(params)
      .then((r: any) => r.data)
      .catch((err: any) => {
        throw {
          errorData: err?.response?.data,
          status: err?.response?.status,
        };
      });

  return [apiRequest];
};
