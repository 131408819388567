import { FormikType, UseFormikHelperResult } from "services/formBuilder/types";

export const useFormikHelper = (formik: FormikType): UseFormikHelperResult => {
  const {
    errors,
    touched,
    status,
    setFieldTouched,
    setStatus,
    setFieldValue,
  }: FormikType = formik;
  const error = (name: string) =>
    (touched?.[name] && errors?.[name]) || (touched?.[name] && status?.[name]);
  const onChange = (name: string, value: any) => {
    setFieldValue(name, value);
    setStatus({ ...status, [name]: "" });
  };
  const onBlur = (name: string) => {
    setFieldTouched(name);
    setStatus({ ...status, [name]: "" });
  };
  return { error, onChange, onBlur };
};
