import React from "react";
import {
  useGetFormFields,
  useGetTranslations,
} from "modules/dynamicForm/apiHooks";
import { useLocation } from "react-router-dom";
import { InjectTransContext } from "system/translations/InjectTransContext";
import Loader from "components/atoms/Loader";

const SystemTemplate: React.FC<{ children: any }> = ({ children }) => {
  const location = useLocation();
  const transactionId: string = location.pathname.split("/")[2];
  const { data, isLoading: isLoadingTranslations } =
    useGetTranslations(transactionId);
  /*  const { isLoading: isLoadingFormTemplate } = useGetFormFields(transactionId);*/

  if (isLoadingTranslations) {
    return <Loader text={""} />;
  }

  return <InjectTransContext messages={data}>{children}</InjectTransContext>;
};

export default SystemTemplate;
