import styled from "styled-components/macro";
import themeOptions from "system/styles/themeOptions";
import { DividerProps } from "components/atoms/Divider/types";

export const DividerContainer = styled.div<DividerProps>`
  display: flex;
  align-items: center;
`;
export const DividerText = styled.div<DividerProps>`
  height: 20px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.paragraphColor};
  padding-right: ${themeOptions.spacing.xs};
  white-space: nowrap;
`;
export const DividerLine = styled.div<DividerProps>`
  width: 100%;
  height: 1px;
  background: ${(props) => `repeating-linear-gradient(
    to right,
    transparent,
    transparent 4px,
    ${props.theme.fieldsBorderColor} 4px,
    ${props.theme.fieldsBorderColor} 10px
  )`};
`;
