import styled, { css } from "styled-components/macro";
import { LoaderProps } from "components/atoms/Loader/types";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import themeOptions from "system/styles/themeOptions";

export const Container = styled.div<LoaderProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  height: 100%;

  ${(props) =>
    props.overflow &&
    css`
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    justify-content: center;
    background: rgba(255 255 255 / 0.7)},
  `}
`;

export const LoadingElement = styled(LoadingIcon)`
  @keyframes loader-keyframes {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  animation: loader-keyframes 0.5s linear infinite;
`;

export const Text = styled.div`
  margin-top: ${themeOptions.spacing.md};
`;
