import React from "react";
import { LoaderProps } from "components/atoms/Loader/types";
import {
  Container,
  LoadingElement,
  Text,
} from "components/atoms/Loader/styles";

const Loader: React.FC<LoaderProps> = ({
  overflow = false,
  text = "Loading...",
  size = 32,
}) => {
  return (
    <Container overflow={overflow}>
      <LoadingElement width={size} height={size} />
      {text && <Text>{text}</Text>}
    </Container>
  );
};

export default Loader;
