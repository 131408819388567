import * as yup from "yup";

export function createYupSchema(fields: any) {
  let schema: any = {};
  if (fields && !Array.isArray(fields)) {
    return yup.object().shape(schema);
  }
  fields?.forEach((field: any) => {
    let validatorRules = yup.lazy((value: "string" | "number") => {
      let type: "string" | "number" | "mixed";
      const allowTypes = ["string", "number"];
      if (allowTypes.includes(typeof value)) {
        // @ts-ignore
        type = typeof value;
      } else {
        type = "mixed";
      }
      let validator: any = yup[type]();
      field?.rules?.forEach((item: any): any => {
        const params = [];
        let value: any = item.value;
        if (!!value) {
          if (item.type === "matches") {
            value = new RegExp(value);
          }
          params.push(value);
        }
        if (!!item.message) {
          params.push(item.message);
        }
        if (item.type === "file_size") {
          validator = validator?.test(
            "fileSize",
            item.message,
            (value: any) => {
              return value ? value.size <= +item.value : true;
            }
          );
        } else if (item.type === "file_format") {
          validator = validator?.test(
            "file_format",
            item.message,
            (value: any) => {
              return value
                ? item.value.includes(value.name.match(/\.([^.]*)$/)[0])
                : true;
            }
          );
        } else {
          if (validator?.[item.type]) {
            validator = validator?.[item.type]?.apply(validator, params);
          }
        }
      });
      return validator;
    });
    schema[field.name] = validatorRules;
  });

  return yup.object().shape(schema);
}
