import React from "react";
import { Container, MessageWrapper } from "pages/error404/styles";
import { ReactComponent as CheckmarkIcon } from "images/icons/transactionError.svg";
import Headline from "components/atoms/Headline";
import { useTrans } from "system/translations/hooks";

const Error404 = () => {
  const { _t } = useTrans();
  return (
    <Container>
      <MessageWrapper>
        <CheckmarkIcon />
        <Headline size={"28px"} value={_t("error404_title")} />
      </MessageWrapper>
    </Container>
  );
};

export default Error404;
