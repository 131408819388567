import { useMemo } from "react";
import Headline from "components/atoms/Headline";
import Paragraph from "components/atoms/Paragraph";
import InfoList from "components/atoms/InfoList";
import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import Divider from "components/atoms/Divider";
import FilesView from "components/atoms/FilesView";
import SimpleSelect from "components/atoms/SimpleSelect";
import UploadBtn from "components/atoms/UploadBtn";

export const useGenerateFields = (fields: any, params: any) => {
  const config = useMemo(() => {
    if (fields) {
      return fields?.map((item: any) => {
        const { name, type, columnParams = {}, ...componentProps } = item;
        if (type === "headline") {
          return {
            component: Headline,
            name: name,
            elementType: "additionalComponent",
            componentProps: {
              value: componentProps?.value,
            },
          };
        }
        if (type === "paragraph") {
          return {
            component: Paragraph,
            name: name,
            elementType: "additionalComponent",
            componentProps: {
              value: componentProps?.value,
            },
          };
        }
        if (type === "divider") {
          return {
            component: Divider,
            name: name,
            elementType: "additionalComponent",
            componentProps: {
              text: componentProps?.value,
            },
          };
        }
        if (type === "infoList") {
          return {
            component: InfoList,
            name: name,
            elementType: "additionalComponent",
            componentProps: {
              ...componentProps,
              credentials: params.credentials,
              transactionDTO: params.transactionDTO
            },
          };
        }
        if (type === "reset_button") {
          return {
            component: Button,
            name: name,
            elementType: "additionalComponent",
            componentProps: {
              ...componentProps,
              kind: "reset",
              type: "button",
              onClick: params?.onCancel,
              label: componentProps?.value,
            },
          };
        }
        if (type === "submit_button") {
          return {
            component: Button,
            name: name,
            elementType: "additionalComponent",
            componentProps: {
              ...componentProps,
              kind: "submit",
              type: "button",
              onClick: params?.onSubmit,
              label: componentProps?.value,
            },
          };
        }
        if (type === "upload_button") {
          return {
            component: UploadBtn,
            name: name,
            componentProps: {
              ...componentProps,
              label: componentProps?.value,
            },
          };
        }
        if (type === "select") {
          return {
            component: SimpleSelect,
            name: name,
            componentProps: {
              ...componentProps,
              /*    defaultValue:
                params?.defaultValues?.[name] || componentProps?.value,*/
            },
          };
        }
        if (type === "files") {
          return {
            component: FilesView,
            name: name,
            componentProps: {
              ...componentProps,
              files: params?.images?.[name],
            },
          };
        }

        return {
          component: Input,
          name: name,
          componentProps: {
            ...componentProps,
            defaultValue:
              params?.defaultValues?.[name] || componentProps?.value,
          },
        };
      });
    }
    return [];
  }, [fields]);

  return config;
};
