const colors = {
  white: "#fff",
  primary: "#18A163",
  c1: "#000",
  c2: "#a7aab6",
  c3: "#072652",
  yellow400: "#FFB020",
  yellowGreen100: "#FDFFD2",
  yellowGreen400: "#c0c637",
  black700: "#2C2C2C",
  red400: "#C5292A",
};
const spacing = {
  xxs: "4px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "20px",
  xlg: "24px",
  xxlg: "32px",
};

const fields = {
  height: "48px",
  fontSize: "14px",
};

const breakpoints = {
  xs: "320px",
  sm: "480px",
  md: "812px",
  lg: "1024px",
  xlg: "1200px",
  xxlg: "1920px",
};
const borders = {
  xs: "4px",
  sm: "8px",
};
const boxShadow = {
  main: "0px 2px 4px rgba(16, 24, 64, 0.08)",
};

let minMedia = { ...breakpoints };
let maxMedia = { ...breakpoints };
let key: keyof typeof breakpoints;
for (key in breakpoints) {
  minMedia[key] = `@media (min-width: ${breakpoints[key]})`;
  maxMedia[key] = `@media (max-width: ${breakpoints[key]})`;
}

const themeOptions = {
  colors,
  spacing,
  borders,
  boxShadow,
  breakpoints,
  minMedia,
  maxMedia,
  fields,
};

export default themeOptions;
