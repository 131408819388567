import axios, { Axios } from "axios";
import backendUrl from "system/api/backendEnvUrl";

const axiosClient: Axios = axios.create({
  baseURL: backendUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

/*
axiosClient.interceptors.request.use((config: any) => {
  const token = getToken();
  if (token) {
    config.headers["authorization"] = token;
  }
  return config;
});
*/

export default axiosClient;
