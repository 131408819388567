import styled from "styled-components/macro";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 16px;
  flex-direction: column;
  max-width: 340px;
  background: ${(props) => props?.theme?.mainBgColor};
  padding: ${(props) =>
    `${props?.theme?.verticalInnerSpace} ${props?.theme?.horizontalInnerSpace}`};
`;
