import styled from "styled-components/macro";
import themeOptions from "system/styles/themeOptions";
import { ButtonProps } from "components/atoms/Button/types";
import { rgba } from "system/styles/helpers";

export const ButtonElement = styled.button<ButtonProps>`
  color: ${themeOptions.colors.black700};
  height: 48px;
  padding: ${themeOptions.spacing.sm} ${themeOptions.spacing.md};
  font-size: 14px;
  cursor: pointer;
  border-radius: ${(props) => props?.theme?.fieldsBorderRadius};
  border: 1px solid ${(props) => props?.theme?.primaryColor};
  background: ${(props) => props?.theme?.primaryColor};
  color: ${themeOptions.colors.white};
  width: 100%;
  &:hover,
  &:active {
    background: ${(props) => props?.theme?.submitButtonActiveColor};
    border-color: ${(props) => props?.theme?.submitButtonActiveColor};
  }
  &.reset {
    background: transparent;
    color: ${(props) => props?.theme?.headlineColor};
    &:hover,
    &:active {
      background: ${(props) => props?.theme?.resetButtonActiveColor};
      border-color: ${(props) => props?.theme?.resetButtonActiveColor};
    }
  }
  &.action {
    background: ${themeOptions.colors.primary};
    color: ${themeOptions.colors.white};
    border-radius: ${themeOptions.borders.xs};
    &:hover,
    &:active {
      background: ${rgba(themeOptions.colors.primary, 0.9)};
      border-color: ${rgba(themeOptions.colors.primary, 0.9)};
    }
  }
`;
