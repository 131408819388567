import styled from "styled-components/macro";
import themeOptions from "system/styles/themeOptions";
import CheckIcon from "images/icons/check.svg";
import { rgba } from "system/styles/helpers";

export const SelectContainer = styled.div<any>`
  position: relative;

  &.full-width {
    width: 100%;
  }

  .dropdown-arrow {
    position: absolute;
    right: 20px;
    top: 22px;
    transition: 250ms;
    stroke: ${(props) => props.theme?.paragraphColor};
  }
  .flipped {
    transition: 250ms;
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  input[value] {
    font-size: 0;
  }

  .simple-select__wrapper.is-open,
  .simple-select__wrapper:hover {
    .simple-select__input {
      background: transparent;
      border: 1px solid;
      border-color: ${(props) => props.theme?.primaryColor};
      outline: none;
      /*      box-shadow: 0 0 0 4px #ddd;*/
      transition: 100ms border;
    }
  }

  label {
    cursor: pointer;
    color: ${(props) =>
      props?.isError ? props.theme?.errorColor : props.theme?.paragraphColor};
    text-transform: capitalize;
    top: 15px;
    left: ${themeOptions.spacing.md};
    position: absolute;
    font-size: ${themeOptions.fields.fontSize};
  }

  &.no-placeholder {
    label {
      top: 17px;
    }
  }

  .simple-select-options {
    position: absolute;
    width: 100%;
    margin-top: 5px;
    border-radius: ${(props) => props.theme.fieldsBorderRadius};
    background: white;
    cursor: pointer;
    padding: 0;
    box-shadow: 0 12px 30px rgba(28, 51, 56, 0.1);
    z-index: 1;

    &.relative-menu {
      position: relative;
    }

    &.top {
      transform: translateY(-160px);
    }

    .simple-select-options__wrap {
      overflow-y: auto;
      overflow-x: hidden;
      height: fit-content;
      max-height: 160px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .simple-option {
        position: relative;
        border-radius: ${(props) => props.theme.fieldsBorderRadius};
        padding: ${themeOptions.spacing.sm} ${themeOptions.spacing.md};
        &.current-selected {
          background: ${themeOptions.colors.white};
          &:after {
            content: "";
            display: block;
            width: 14px;
            height: 10px;
            background: url(${CheckIcon}) no-repeat;
            position: absolute;
            top: 18px;
            right: 12px;
          }
        }
        .simple-select__label {
          display: block;
          padding: 8px 10px;
        }
        &.with-img {
          position: relative;
          .simple-option__label {
            display: block;
            text-align: center;
            line-height: 35px;
            padding: 0;
          }
          .simple-select__icon-img {
            position: absolute;
            top: 8px;
            left: 15px;
            pointer-events: none;
          }
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .simple-select__input {
    padding: 0;
    width: 100%;
    top: 16px;
    font-size: ${themeOptions.fields.fontSize};
    height: 46px;
    background: transparent;
    color: ${(props) => props.theme?.paragraphColor};
    border: 1px solid;
    border-color: ${(props) =>
      props?.isError
        ? props.theme?.errorColor
        : props.theme?.fieldsBorderColor};
    border-radius: ${(props) => props.theme.fieldsBorderRadius};
    cursor: pointer;

    &.custom-bg {
      background-color: transparent;
    }
  }

  .simple-select__placeholder {
    position: absolute;
    font-size: ${themeOptions.fields.fontSize};
    top: ${themeOptions.spacing.md};
    left: 15px;
    transition: 250ms;
    color: ${(props) => rgba(props.theme?.paragraphColor, 0.8)};

    /*    &.centered {
      margin: 0;
      padding: 0;
      position: absolute;
      transform: translate(5%, 30%);
      transition: 250ms;
      font-weight: 600;
      font-size: ${themeOptions.fields.fontSize};
      color: red;
    }*/
  }

  .simple-select__error {
    font-size: 13px;
    line-height: 16px;
    color: ${themeOptions.colors.red400};
    padding: 4.5px 0 0 16px;
  }

  &.lang-select-container {
    grid-area: bot;
    width: 180px;
    justify-self: center;
    align-items: center;

    &.full-width {
      width: 100%;
    }

    .simple-select {
      .simple-select__input {
        height: 40px;
      }
    }

    .dropdown-arrow {
      top: 18px;
    }

    label {
      top: 10px;
      font-weight: 600;
      gap: 10px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
`;

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: ${themeOptions.spacing.xxs};
`;
export const Label = styled.div<any>`
  color: ${(props) =>
    props?.isError ? props.theme?.errorColor : props.theme?.paragraphColor};
  font-weight: 600;
  font-size: ${themeOptions.fields.fontSize};
`;
