import React from "react";
import { DividerProps } from "components/atoms/Divider/types";
import {
  DividerContainer,
  DividerLine,
  DividerText,
} from "components/atoms/Divider/styles";

const Divider: React.FC<DividerProps> = ({ text }) => {
  return (
    <DividerContainer>
      {text && <DividerText>{text}</DividerText>}
      <DividerLine />
    </DividerContainer>
  );
};

export default Divider;
