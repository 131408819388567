import styled, { css } from "styled-components/macro";
import themeOptions from "system/styles/themeOptions";
import { InputProps, StylesType } from "components/atoms/Input/types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeOptions.spacing.xxs};
`;

/*    primaryColor: values?.styles?.primaryColor,
secondaryColor: values?.styles?.secondaryColor,
headlineColor: values?.styles?.headlineColor,
textColor: values?.styles?.textColor,
errorColor: values?.styles?.errorColor,
mainBgColor: values?.styles?.mainBgColor,
fieldsBorderColor: values?.styles?.fieldsBorderColor,
verticalInnerSpace: values?.styles?.verticalInnerSpace,
horizontalInnerSpace: values?.styles?.horizontalInnerSpace,
fieldsSpace: values?.styles?.fieldsSpace,
bgBorderRadius: values?.styles?.bgBorderRadius,
fieldsBorderRadius: values?.styles?.fieldsBorderRadius,*/

export const InputElement = styled.input<InputProps>`
  border-radius: ${(props) => props.theme.fieldsBorderRadius};
  padding: ${themeOptions.spacing.md} ${themeOptions.spacing.md};
  padding-bottom: ${themeOptions.spacing.sm};
  font-size: ${themeOptions.fields.fontSize};
  border: 1px solid ${themeOptions.colors.primary};
  border-color: ${(props) =>
    props?.isError ? props.theme?.errorColor : props.theme?.fieldsBorderColor};
  outline: none;
  &:hover,
  &:focus {
    border-color: ${(props) => props.theme?.primaryColor};
  }
`;

export const Label = styled.label<StylesType>`
  color: ${(props) =>
    props?.isError ? props.theme?.errorColor : props.theme?.paragraphColor};
  font-weight: 600;
  font-size: 14px;
`;
export const Error = styled.div<StylesType>`
  color: ${(props) => props?.theme?.errorColor};
  font-size: 12px;
`;

/*
export const Container = styled.div<ContainerProps>`
  display: grid;
  justify-items: center;
  grid-row-gap: 20px;

  ${(props) =>
    props.overflow &&
    css`
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      align-content: center;
      background: ${rgba(color.blue400, 0.5)};
    `}

  ${(props) =>
    props.center &&
    css`
      align-content: center;
      align-items: center;
    `}

  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}

  ${(props) =>
    props.size === "lg" &&
    css`
      ${Animation} {
        height: 46px;
        width: 46px;

        &:before,
        &:after {
          height: 42px;
          width: 42px;
        }
      }
    `}
`;
*/
