export const getNestedValue = (obj: any = {}, path: any, divider = ".") => {
  let properties = Array.isArray(path) ? path : path.split(divider);
  return properties.reduce((p: any, c: any) => p && p[c], obj);
};
export const getFirstObjInArray = (arr: any, val: any) =>
  arr.filter((el: any) => el.value === val).shift();

export const isWindowClosable =
  window.opener != null || window.history.length == 1;

export const checkIsFile = (val: any) => {
  return (
    typeof val === "object" &&
    !Array.isArray(val) &&
    Object.prototype.toString.call(val) === "[object File]"
  );
};

export const parseFormData = (values: any) => {
  const resultData = new FormData();
  let startKey = "";

  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = parsDataKey === "" ? key : parsDataKey + `[${key}]`;
      if (
        typeof values[key] === "object" &&
        !Array.isArray(values[key]) &&
        Object.prototype.toString.call(values[key]) !== "[object File]"
      ) {
        parseQ(values[key], resKey);
      } else if (typeof values[key] === "boolean") {
        if (!!values[key]) {
          resultData.append(resKey, "1");
        } else {
          resultData.append(resKey, "0");
        }
      } else if (Array.isArray(values[key])) {
        values[key].forEach((item: any, idx: any) => {
          if (
            typeof item === "object" &&
            !Array.isArray(item) &&
            Object.prototype.toString.call(item) !== "[object File]"
          ) {
            parseQ(item, resKey + `[${idx}]` + parsDataKey);
          } else {
            resultData.append(resKey + `[${idx}]`, item);
          }
        });
      } else {
        resultData.append(resKey, values[key]);
      }
    }
  };

  parseQ(values, startKey);

  return resultData;
};

export const checkTransParams = (data: any, _t: any) => {
  const regex = /\#(.*?)\#/g;
  const parse = (arg: any) => {
    let data = { ...arg };
    if (typeof data === "object") {
      for (let key in data) {
        if (typeof data[key] === "object" && !Array.isArray(data[key])) {
          data[key] = parse(data[key]);
        } else if (Array.isArray(data[key])) {
          data[key] = data[key].map((item: any) => {
            if (typeof item === "object" && !Array.isArray(item)) {
              return parse(item);
            }
            return item;
          });
        } else if (typeof data[key] === "string" && regex.test(data[key])) {
          const val = data[key].split(regex);
          const matchedKey = val ? val[1] : data[key];
          data[key] = _t(matchedKey);
        } else {
          data[key] = data[key];
        }
      }
    }
    return data;
  };
  return data.map((item: any) => {
    return parse(item);
  });
};

export const getValueByPath = (obj: any, path: string) => {
  const keys = path.split(/\.|\[/).filter(Boolean);
  return keys.reduce((acc, key) => {
    return acc?.[key];
  }, obj);
}

export const prepareAmount = (value: number) => value / 100

export const getPreparedValueInfoList = (value: string, data: any) => {
    let jsonInstructions: any;
    try {
      JSON.parse(value);
      jsonInstructions = JSON.parse(value);
    } catch (e) {
      return value
    }

  return jsonInstructions?.valuesOrder?.map((item: string) => {
      const jsonInstructionsPath = jsonInstructions?.valuesData?.[item]?.split('.') || []
      if (jsonInstructionsPath[0] === '$$transactionDTO') {
        const path = jsonInstructionsPath.slice(1).join('.')

        return getValueByPath(data, path)
      } else {
        return item
      }
    }).join(' ') || value
}