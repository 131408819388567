import React, { useMemo } from "react";
import { InfoListProps } from "components/atoms/InfoList/types";
import {
  Container,
  Item,
  ItemLabel,
  ItemValue,
} from "components/atoms/InfoList/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "images/icons/copy.svg";
import { toast } from "react-hot-toast";
import { useTrans } from "system/translations/hooks";
import { getPreparedValueInfoList, prepareAmount } from "system/helpers/helperFunctions";

const InfoList: React.FC<InfoListProps> = ({ options, credentials, transactionDTO }) => {
  const { _t } = useTrans();
  const onCopy = () => {
    toast.success(_t("copied"));
  };
  const matchedOptions = useMemo(() => {
    const result: any = [];
    options?.forEach((option) => {
      const key = option.key;
      const label = option.value;
      credentials?.forEach((credential) => {
        if (key === credential.key) {
          result.push({ 
            label: label, 
            value: getPreparedValueInfoList(credential.value, { ...transactionDTO, amount: prepareAmount(transactionDTO.amount) })
          });
        }
      });
    });
    return result;
  }, [options, credentials, transactionDTO]);

  return (
    <Container>
      {matchedOptions?.map((item: any) => {
        return (
          <Item>
            <ItemLabel>{item.label}</ItemLabel>
            <ItemValue>
              {item.value}
              <CopyToClipboard onCopy={onCopy} text={item.value}>
                <CopyIcon />
              </CopyToClipboard>
            </ItemValue>
          </Item>
        );
      })}
    </Container>
  );
};

export default InfoList;
