import React from "react";
import { Container, MessageWrapper } from "pages/transactionError/styles";
import { ReactComponent as TransactionInProgressIcon } from "images/icons/transactionInProgress.svg";
import Headline from "components/atoms/Headline";
import Paragraph from "components/atoms/Paragraph";
import Button from "components/atoms/Button";
import { useTrans } from "system/translations/hooks";
import { isWindowClosable } from "system/helpers/helperFunctions";

const Progress = () => {
  const { _t } = useTrans();
  const closeWindow = () => {
    window.close();
  };
  return (
    <Container>
      <MessageWrapper>
        <TransactionInProgressIcon />
        <Headline size={"24px"} value={_t("progress_message_title")} />
        <Paragraph size={"14px"} value={_t("progress_message_description")} />
        {isWindowClosable && (
          <Button
            onClick={closeWindow}
            kind={"action"}
            label={_t("close_window")}
          />
        )}
      </MessageWrapper>
    </Container>
  );
};

export default Progress;
