export const defaultThemeVariables = {
  primaryColor: "",
  secondaryColor: "",
  headlineColor: "",
  paragraphColor: "",
  errorColor: "",
  mainBgColor: "",
  fieldsBorderColor: "",
  submitButtonActiveColor: "",
  resetButtonActiveColor: "",
  fieldsBgColor: "",
  verticalInnerSpace: 0,
  horizontalInnerSpace: 0,
  fieldsSpace: 0,
  bgBorderRadius: 0,
  fieldsBorderRadius: 0,
  actionBtnColor: "",
  actionBtnActiveColor: "",
};
